import { useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const SearchCommitments = ({
  onSearch,
  searchQuery,
  setSearchQuery,
  handleRefresh,
  exportData,
  isExporting,
  onExport,
  onExportComplete,
}) => {
  const [checked, setChecked] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <TextField
        label="Email"
        variant="outlined"
        sx={{ flexGrow: 5 }}
        value={searchQuery?.email}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, email: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="Phone"
        variant="outlined"
        sx={{ flexGrow: 2 }}
        value={searchQuery?.phone}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, phone: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="First Name"
        variant="outlined"
        sx={{ flexGrow: 2 }}
        value={searchQuery?.firstName}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, firstName: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <TextField
        label="Last Name"
        variant="outlined"
        sx={{ flexGrow: 3 }}
        value={searchQuery?.lastName}
        onChange={(event) =>
          setSearchQuery({ ...searchQuery, lastName: event.target.value })
        }
        onKeyDown={handleKeyDown}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(event) => {
              setChecked(event.target.checked);
              setSearchQuery({
                ...searchQuery,
                notInRock: event.target.checked,
              });
            }}
            onKeyDown={handleKeyDown}
          />
        }
        label="Not in Rock"
      />
      <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onSearch}>
        Search
      </Button>
      <IconButton onClick={handleRefresh} title="Refresh">
        <RefreshRoundedIcon color="primary" fontSize="large" />
      </IconButton>
      {!isExporting && (
        <Button variant="contained" sx={{ flexGrow: 1 }} onClick={onExport}>
          Export
        </Button>
      )}
      {isExporting && !isEmpty(exportData?.data) && (
        <CSVLink
          style={{ paddingTop: '0.625rem' }}
          data={exportData?.data || []}
          headers={exportData?.headers}
          filename={`Commitment-Export-${new Date().toISOString()}.csv`}
          onClick={onExportComplete}
        >
          <FileDownloadIcon color="primary" fontSize="large" />
        </CSVLink>
      )}
    </Box>
  );
};

SearchCommitments.propTypes = {
  exportData: {
    data: PropTypes.array,
    headers: PropTypes.array,
  },
  handleRefresh: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  searchQuery: {
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    notInRock: PropTypes.bool,
    phone: PropTypes.string,
  },
  setSearchQuery: PropTypes.func.isRequired,
  isExporting: PropTypes.bool,
  onExport: PropTypes.func,
  onExportComplete: PropTypes.func,
};

export default SearchCommitments;
