import { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Alert } from '@mui/material/index';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import restService from '../../services/restService';

const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getTimeString = ({ hour, minute }) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0);

  return date.toLocaleTimeString();
};

const LivestreamTable = forwardRef(({ setState }, ref) => {
  useImperativeHandle(ref, () => ({
    refreshData: fetchData,
  }));

  const [livestreams, setLivestreams] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationChanged, setPaginationChanged] = useState(false);
  // eslint-disable-next-line no-magic-numbers
  const [pageSize, setPageSize] = useState(5);
  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  const handleDeleteClick = (id) => () => {
    setState({
      modalOpen: true,
      id,
      action: 'delete',
    });
  };

  const columns = [
    {
      field: 'day',
      headerName: 'Day',
      flex: 1,
      valueGetter: (params) => DAYS_OF_THE_WEEK[params.row?.day || 0],
    },
    {
      field: 'startsOn',
      headerName: 'Starts On',
      flex: 1,
      valueGetter: (params) => {
        const hour = params.row?.startsAtHour;
        const minute = params.row?.startsAtMinute;

        return !hour || !minute
          ? ''
          : getTimeString({
              hour,
              minute,
            });
      },
    },
    {
      field: 'endsOn',
      headerName: 'Ends On',
      flex: 1,
      valueGetter: (params) => {
        const hour = params.row?.endsAtHour;
        const minute = params.row?.endsAtMinute;

        return !hour || !minute
          ? ''
          : getTimeString({
              hour,
              minute,
            });
      },
    },
    {
      field: 'duration',
      headerName: 'Duration (minutes)',
      flex: 1,
      valueGetter: (params) => params.row?.durationInMinutes,
    },
    {
      field: 'url',
      headerName: 'Url',
      flex: 1,
      valueGetter: (params) => params.row?.url,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) => params.row?.createdAt,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            key="delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const fetchData = useCallback(() => {
    restService
      .callEndpoint({
        endpoint: 'getLivestreams',
        params: { pageSize, page: currentPage },
        method: 'get',
      })
      .then((response) => {
        const data = response.data;
        setLivestreams(data.livestreams);
        setTotalRowCount(data.totalCount);
        setPaginationChanged(false);
      });
  }, [currentPage, pageSize]);

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    setPaginationChanged(true);
  };

  useEffect(() => {
    if (paginationChanged) {
      fetchData();
    }
  }, [fetchData, paginationChanged]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isEmpty(livestreams) ? (
    <Alert variant="outlined" severity="info">
      There are no livestreams scheduled yet.
    </Alert>
  ) : (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={livestreams}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: 0,
          pageSize: pageSize,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        getRowHeight={() => 'auto'}
      />
    </div>
  );
});

LivestreamTable.propTypes = {
  setState: PropTypes.func.isRequired,
};

export default LivestreamTable;
