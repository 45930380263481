import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import ContentSearchModal from "./ContentSearchModal";

export default function SelectContent({
  content,
  setContent,
  required = false,
  description = "",
  disabled = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <ContentSearchModal
        open={modalOpen}
        handleModalClose={handleModalClose}
        setContent={setContent}
      />
      <Typography variant="h4">{`Linked Content${
        required ? "*" : ""
      }`}</Typography>
      <Typography variant="body1">{description}</Typography>
      <SearchResults rows={!isEmpty(content) ? [content] : []} />
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          disabled={disabled}
          onClick={() => setModalOpen(true)}
          variant="contained"
        >
          {content._id ? "Change Content" : "Select Content"}
        </Button>
      </Box>
    </>
  );
}
SelectContent.propTypes = {
  content: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    type: PropTypes.string,
  }),
  setContent: PropTypes.func.isRequired,
  required: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

const SearchResults = ({ rows, totalRowCount }) => {
  const columns = [
    {
      field: "_id",
      headerName: "id",
      flex: 1,
      valueGetter: (params) => params.row._id,
    },
    {
      field: "type",
      headerName: "Content Type",
      flex: 1,
      valueGetter: (params) => params.row.type,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 3,
      valueGetter: (params) => params.row.fields.title,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) =>
        params.row.sys.createdAt
          ? new Date(params.row.sys.createdAt).toDateString()
          : "",
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: "center",
    align: "center",
  }));

  const renderEmpty = () => (
    <Box
      sx={{
        padding: "2rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6">No Content Selected</Typography>
    </Box>
  );

  return (
    <Box sx={{ flex: 1 }}>
      <DataGrid
        columns={columns}
        autoHeight
        rows={rows}
        disableColumnMenu
        hideFooter
        disableRowSelectionOnClick
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        slots={{
          noRowsOverlay: renderEmpty,
        }}
      />
    </Box>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      createdAt: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  totalRowCount: PropTypes.number,
};
