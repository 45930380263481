import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  bgcolor: "white",
  border: "2px solid #000",
  p: 4,
};

// eslint-disable-next-line react/prop-types
export default function ViewJournals({ user, clearSelectedUser }) {
  const handleClose = () => clearSelectedUser();

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            onClick={handleClose}
            style={{
              float: "right",
              fontSize: 20,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            X
          </Button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {user.profile.fullName}
          </Typography>
          <Typography id="modal-modal-description" variant="h7">
            Journals
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

ViewJournals.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
};
