import { useState, useRef } from "react";

import { Box } from "@mui/material";

import AddDailyScriptureButton from "../components/daily-scripture/AddDailyScriptureButton";
import AddDailyScriptureModal from "../components/daily-scripture/AddDailyScriptureModal";
import DailyScriptureTable from "../components/daily-scripture/DailyScriptureTable";

const DailyScripture = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [lastDate, setLastDate] = useState(new Date());
  const tableRef = useRef(null);

  const handleCloseAddScriptureModal = () => {
    setShowAddModal(false);
    tableRef.current.refreshData();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      <h1>Daily Scripture Readings</h1>
      <AddDailyScriptureButton onClick={() => setShowAddModal(true)} />
      <DailyScriptureTable ref={tableRef} setLastDate={setLastDate} />
      {showAddModal && (
        <AddDailyScriptureModal
          handleClose={handleCloseAddScriptureModal}
          lastDate={lastDate}
        />
      )}
    </Box>
  );
};

export default DailyScripture;
