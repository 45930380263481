import { Button, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';

const displayGuideProgressMetric = (metric) => {
  switch (metric) {
    case 'section':
      return 'Heading';
    case 'level':
      return 'Level';
    default:
      return '';
  }
};

const SearchResults = ({
  rows,
  totalRowCount,
  currentPage = 0,
  setCurrentPage,
  pageSize,
  setPageSize,
  deleteContent,
  onPaginationChange = noop,
}) => {
  const columns = [
    {
      field: 'linkedId',
      headerName: 'Linked Content Id',
      flex: 1,
      valueGetter: (params) => get(params, 'row.linkedContent._id', ''),
    },
    {
      field: 'linkedType',
      headerName: 'Linked Content Type',
      flex: 1,
      valueGetter: (params) => get(params, 'row.linkedContent.type', ''),
    },
    {
      field: 'linkedTitle',
      headerName: 'Linked Content Title',
      flex: 2,
      valueGetter: (params) => get(params, 'row.linkedContent.title', ''),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) =>
        params.row?.createdAt ? new Date(params.row.createdAt).toString() : '',
    },
    {
      field: 'heading',
      headerName: 'Heading',
      flex: 1,
      valueGetter: (params) => get(params, 'row.heading', ''),
    },
    {
      field: 'subheading',
      headerName: 'Subheading',
      flex: 1,
      valueGetter: (params) => get(params, 'row.subheading', ''),
    },
    {
      field: 'buttonText',
      headerName: 'Button Text',
      flex: 1,
      valueGetter: (params) => get(params, 'row.buttonText', ''),
    },
    {
      field: 'progressMetric',
      headerName: 'Progress Metric',
      flex: 1,
      valueGetter: (params) =>
        displayGuideProgressMetric(params.row?.guideProgress?.metric),
    },
    {
      field: 'progressUnit',
      headerName: 'Progress Unit',
      flex: 1,
      valueGetter: (params) => get(params, 'row.guideProgress.unit', ''),
    },
    {
      field: 'backgroundImage',
      headerName: 'Background Image',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={() => window.open(params.row?.backgroundImage, '_blank')}
        >
          View Image
        </Button>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      flex: 1,
      renderCell: (params) => <Link href={params.row.url}>{params.row?.url}</Link>,
    },
    {
      field: 'startOn',
      headerName: 'Start On',
      flex: 1,
      valueGetter: (params) =>
        params.row?.startOn ? new Date(get(params, 'row.startOn')).toString() : '',
    },
    {
      field: 'endOn',
      headerName: 'End On',
      flex: 1,
      valueGetter: (params) =>
        params.row?.endOn ? new Date(get(params, 'row.endOn')).toString() : '',
    },
    {
      field: 'delete',
      headerName: '',
      flex: 1,
      renderCell: (params) => (
        <>
          <Button onClick={() => deleteContent(params.row?._id)} variant="contained">
            Delete
          </Button>
        </>
      ),
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }));

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.pageSize === pageSize ? paginationModel.page : 0);

    setPageSize(paginationModel.pageSize);
    onPaginationChange();
  };

  // eslint-disable-next-line no-magic-numbers
  const pageSizeOptions = [5, 10, 20, 30];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode="server"
        rowCount={totalRowCount}
        getRowId={(row) => row._id}
        paginationModel={{
          page: currentPage, // DataGrid uses zero-based page numbering
          pageSize: pageSize,
        }}
      />
    </div>
  );
};
SearchResults.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      prayerText: PropTypes.string,
      createdAt: PropTypes.string,
      prayedForCount: PropTypes.number,
    })
  ),
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  deleteContent: PropTypes.func.isRequired,
  onPaginationChange: PropTypes.func,
};

export default SearchResults;
