import axios from "axios";
import CryptoJS from "crypto-js";
import { get } from "lodash";

const HTTP_CODES = {
  OK: 200,
  NO_CONTENT: 204,
};

/*hardcode prod and localhost to get auth working in production
//todo investigate why vercel env variables are not being picked up for process.env.REACT_APP_API_BASE_URL*/
const baseURL = process.env.REACT_APP_API_BASE_URL.includes("localhost")
  ? process.env.REACT_APP_API_BASE_URL
  : "https://app-api.crossroads.net/dashboard";

export const getAuthorizationHeader = (body) => {
  return encrypt(JSON.stringify(body));
};

const encrypt = (data) => {
  let encrypted = CryptoJS.AES.encrypt(data, process.env.REACT_APP_ACCESS_KEY);
  encrypted = encrypted.toString();
  return encrypted;
};

const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

const restService = {
  callEndpoint: ({ endpoint, body = null, method = "post", params = null }) => {
    const url = `${baseURL}/${endpoint}`;
    if (method.toLowerCase() === "post") {
      return axios
        .post(
          url,
          { data: body, encryptedData: encrypt(JSON.stringify(body)) },
          config
        )
        .then(function (response) {
          if (
            response.status === HTTP_CODES.OK ||
            response.status === HTTP_CODES.NO_CONTENT
          ) {
            return get(response, "data");
          }
        })
        .catch(function (error) {
          // TODO: determine which errors to throw
          console.log(error); // eslint-disable-line no-console
          throw error;
        });
    } else if (method.toLowerCase() === "put") {
      return axios
        .put(
          url,
          { data: body, encryptedData: encrypt(JSON.stringify(body)) },
          config
        )
        .then(function (response) {
          if (response.status === HTTP_CODES.OK) {
            return get(response, "data");
          }
        })
        .catch(function (error) {
          // TODO: determine which errors to throw
          console.log(error); // eslint-disable-line no-console
          throw error;
        });
    } else if (method.toLowerCase() === "get") {
      return axios.get(url, { ...config, params }).then((response) => {
        return response;
      });
    } else {
      throw new Error(
        `Invalid HTTP Request Type of ${method} was passed. Please make a GET, POST or PUT call.`
      );
    }
  },
  login: (username, password, setIsAuthenticated) => {
    const url = `${baseURL}/login`;
    const encryptedPassword = encrypt(password);
    return axios
      .post(
        url,
        {
          username,
          password: encryptedPassword,
        },
        config
      )
      .then((response) => {
        if (response.status === HTTP_CODES.OK) {
          setIsAuthenticated(true);
        }
      })
      .catch(function (error) {
        // TODO: determine which errors to throw
        console.log(error); // eslint-disable-line no-console
        throw error;
      });
  },
  isAuthTokenValid: (setIsAuthenticated) => {
    const url = `${baseURL}/isAuthTokenValid`;
    return axios
      .get(url, config)
      .then((response) => {
        if (response.status === HTTP_CODES.OK) {
          setIsAuthenticated(true);
          return true;
        }
        // TODO: what should we do if the status is not OK?
        return false;
      })
      .catch((error) => setIsAuthenticated(false));
  },
  logout: (setIsAuthenticated) => {
    const url = `${baseURL}/logout`;
    return axios.get(url, config).then((response) => {
      setIsAuthenticated(false);
    });
  },
};

export default restService;
