/* eslint react/prop-types: 0 */
import * as React from "react";
import { useState } from "react";

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { cloneDeep, get, omit, set } from "lodash";

import restService from "../services/restService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  bgcolor: "white",
  border: "2px solid #000",
  p: 4,
};

const RenderProfileFields = ({ state, setState, user }) => {
  // eslint-disable-next-line no-empty-pattern
  const { updatedUser, modifiedFields = [] } = state;
  const profileFieldsToDisplay = {
    "Rock Id": "identity.rockId",
    "MP Contact Id": "identity.contactId",
    "Created At": "createdAt",
    "First Name": "profile.firstName",
    "Last Name": "profile.lastName",
    "Full Name": "profile.fullName",
    Email: "profile.email",
    Site: "profile.site",
  };

  const profileOriginalFieldsToDisplay = {
    Site: "profileOriginal.site",
    "First Name": "profileOriginal.firstName",
    "Last Name": "profileOriginal.lastName",
    "Full Name": "profileOriginal.fullName",
    Email: "profileOriginal.email",
    Phone: "profileOriginal.phone",
  };
  const hasDeviceInfo = updatedUser?.deviceInfo?.length > 0;
  let profileReadOnlyDeviceFields = {
    "Last Seen At": "Unknown",
    "Device Manufacturer": "Unknown",
    "App Version": "Unknown",
  };
  if (hasDeviceInfo) {
    profileReadOnlyDeviceFields = {
      "Last Seen At":
        updatedUser?.deviceInfo[updatedUser?.deviceInfo?.length - 1]
          ?.lastSeenAt,
      "Device Manufacturer":
        updatedUser?.deviceInfo[updatedUser?.deviceInfo?.length - 1]
          ?.manufacturer,
      "App Version":
        updatedUser?.deviceInfo[updatedUser?.deviceInfo?.length - 1]
          ?.appVersion,
    };
  }

  const checkModifiedFields = (value, field) => {
    if (field === "deleted" || field === "banned") {
      if (modifiedFields.indexOf(field) !== -1) {
        return modifiedFields.filter(
          (modifiedField, index) => index !== modifiedFields.indexOf(field)
        );
      }
    }
    if (
      value === get(user, profileFieldsToDisplay[field]) &&
      modifiedFields.indexOf(field) !== -1
    ) {
      return modifiedFields.filter(
        (modifiedField, index) => index !== modifiedFields.indexOf(field)
      );
    } else if (
      value !== get(user, profileFieldsToDisplay[field]) &&
      modifiedFields.indexOf(field) === -1
    ) {
      return [...modifiedFields, field];
    }
    return modifiedFields;
  };

  return (
    <>
      {Object.keys(profileFieldsToDisplay).map((field, index) => (
        <TextField
          id="outlined-basic"
          label={field}
          value={get(updatedUser, profileFieldsToDisplay[field])}
          onChange={(e) => {
            setState({
              ...state,
              updatedUser: set(
                updatedUser,
                profileFieldsToDisplay[field],
                e.target.value
              ),
              modifiedFields: checkModifiedFields(e.target.value, field),
            });
          }}
          variant="outlined"
          key={index}
          sx={{
            width: "300px",
            borderRadius: "5px",
            borderColor: "blue",
            backgroundColor: "#eee",
            input: { color: "black" },
            label: {
              color: modifiedFields.indexOf(field) === -1 ? "black" : "red",
              fontSize: 20,
              margin: "none",
              fontWeight: 600,
            },
            margin: "15px",
          }}
        />
      ))}
      {Object.keys(profileReadOnlyDeviceFields).map((field) => (
        <TextField
          id="outlined-basic"
          label={field}
          value={profileReadOnlyDeviceFields[field]}
          variant="outlined"
          key={field}
          sx={{
            width: "300px",
            borderRadius: "5px",
            borderColor: "blue",
            backgroundColor: "#eee",
            input: { color: "black" },
            label: {
              color: "black",
              fontSize: 20,
              margin: "none",
              fontWeight: 600,
            },
            margin: "15px",
          }}
        />
      ))}
      <FormControlLabel
        key={"banned"}
        control={
          <Checkbox
            defaultChecked={updatedUser.roles?.includes("banned")}
            onChange={(e) => {
              let roles = updatedUser.roles || [];
              if (e.target.checked && roles.indexOf("banned") === -1) {
                roles.push("banned");
              } else if (roles.indexOf("banned") !== -1) {
                roles.splice(roles.indexOf("banned"), 1);
              }
              setState({
                ...state,
                updatedUser: set(updatedUser, "roles", roles),
                modifiedFields: checkModifiedFields(e.target.value, "banned"),
              });
            }}
          />
        }
        label={"Banned"}
        sx={{
          width: "120px",
          borderRadius: "5px",
          borderColor: "blue",
          backgroundColor: "#eee",
          input: { color: "black" },
          label: {
            color: "black",
            fontSize: 20,
            margin: "none",
            fontWeight: 600,
          },
          margin: "20px",
          padding: "5px",
        }}
      />
      <FormControlLabel
        key={"deleted"}
        control={
          <Checkbox
            defaultChecked={updatedUser.deleted}
            onChange={(e) => {
              if (!e.target.checked)
                return setState({
                  ...state,
                  updatedUser: omit(updatedUser, "deleted"),
                  modifiedFields: checkModifiedFields(
                    e.target.value,
                    "deleted"
                  ),
                });
              setState({
                ...state,
                updatedUser: set(updatedUser, "deleted", true),
                modifiedFields: checkModifiedFields(e.target.value, "deleted"),
              });
            }}
          />
        }
        label={"Deleted"}
        sx={{
          width: "120px",
          borderRadius: "5px",
          borderColor: "blue",
          backgroundColor: "#eee",
          input: { color: "black" },
          label: {
            color: "black",
            fontSize: 20,
            margin: "none",
            fontWeight: 600,
          },
          margin: "20px",
          padding: "5px",
        }}
      />
      {updatedUser.profileOriginal &&
        Object.keys(profileOriginalFieldsToDisplay).map((field, index) => (
          <TextField
            id="outlined-basic"
            label={`OLD ${field}`}
            value={get(updatedUser, profileOriginalFieldsToDisplay[field])}
            onChange={(e) => {
              setState({
                ...state,
                updatedUser: set(
                  updatedUser,
                  profileOriginalFieldsToDisplay[field],
                  e.target.value
                ),
                modifiedFields: checkModifiedFields(e.target.value, field),
              });
            }}
            variant="outlined"
            key={index}
            sx={{
              width: "300px",
              borderRadius: "5px",
              borderColor: "blue",
              backgroundColor: "#eee",
              input: { color: "black" },
              label: {
                color: modifiedFields.indexOf(field) === -1 ? "black" : "red",
                fontSize: 20,
                margin: "none",
                fontWeight: 600,
              },
              margin: "15px",
            }}
          />
        ))}
    </>
  );
};

// eslint-disable-next-line react/prop-types
export default function EditUser({ user, clearSelectedUser }) {
  const handleClose = () => clearSelectedUser();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [state, setState] = useState({
    updatedUser: cloneDeep(user),
    modifiedFields: [],
  });

  const handleSaveModalOpen = () => {
    setSaveModalOpen(true);
  };

  const handleSaveModalClose = () => {
    setSaveModalOpen(false);
  };

  const handleUpdateUser = () => {
    restService
      .callEndpoint({
        endpoint: "updateUser",
        body: {
          newUserDoc: state.updatedUser,
          modifiedFields: state.modifiedFields,
          oldUserDoc: user,
        },
        method: "put",
      })
      .then((data) => {
        if (data) {
          handleClose();
        } else {
          console.log("Error, try again later!"); // eslint-disable-line no-console
        }
      });
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              float: "right",
              fontSize: 15,
              fontWeight: "bold",
              cursor: "pointer",
              background: "red",
            }}
          >
            X
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            {user.profile.firstName} {user.profile.lastName}
          </Typography>
          <RenderProfileFields state={state} setState={setState} user={user} />
          <Button
            variant="contained"
            onClick={handleSaveModalOpen}
            style={{
              position: "absolute",
              bottom: 40,
              right: 40,
              fontSize: 20,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            disabled={state.modifiedFields.length ? false : true}
          >
            Save User
          </Button>
          <Dialog
            open={saveModalOpen}
            onClose={handleSaveModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Update User"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The following fields have been modified:
                {state.modifiedFields.map((field) => (
                  <p style={{ margin: "0px" }} key={field}>
                    {field}
                  </p>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveModalClose}>Go Back</Button>
              <Button onClick={handleUpdateUser} autoFocus>
                Update User
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Modal>
    </div>
  );
}
