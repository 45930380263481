import { useState } from "react";

import { Box, Button, Paper, TextField } from "@mui/material";
import PropTypes from "prop-types";

import restService from "../services/restService";

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("");
  // TODO: Before deploying, save the password in a secure way
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    restService.login(username, password, setIsAuthenticated).catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          required
          label="email"
          value={username}
          onChange={handleUsernameChange}
        />
        <TextField
          required
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          variant="contained"
          size="large"
          sx={{ width: "100%" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Box>
    </Paper>
  );
};
Login.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Login;
