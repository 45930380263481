/* eslint react/prop-types: 0 */
import { useState, useEffect } from "react";

import {
  TextField as MUITextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";


import formatPassage from "./formatPassage";
import scriptureBooks from "./scriptureBooks";

import restService from "../../services/restService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "70%",
  bgcolor: "white",
  border: "2px solid #000",
  p: 4,
};

const TextField = ({ label, value, onChange, moreSx = {}, ...rest }) => (
  <MUITextField
    id="outlined-basic"
    label={label}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    variant="outlined"
    sx={{
      minWidth: "300px",
      borderRadius: "5px",
      backgroundColor: "#eee",
      input: { color: "black" },
      label: {
        color: "black",
        fontSize: 20,
        margin: "none",
        fontWeight: 600,
      },
      ...moreSx,
    }}
    {...rest}
  />
);

const DEFAULT_PROMPT = "What stood out to you?";

const RenderFields = ({ setValues, lastDate }) => {
  const [activeAt, setActiveAt] = useState(
    lastDate ? moment(lastDate).add(1, "day").toDate() : new Date()
  );
  const [book, setBook] = useState("");
  const [chapter, setChapter] = useState("");
  const [startVerse, setStartVerse] = useState("");
  const [endVerse, setEndVerse] = useState("");
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT);

  useEffect(() => {
    setValues({
      activeAt,
      book,
      chapter,
      startVerse,
      endVerse,
      prompt,
    });
  }, [activeAt, book, chapter, startVerse, endVerse, prompt, setValues]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5rem",
        margin: "60px 0 15px 15px",
        alignItems: "flex-start",
      }}
    >
      <DatePicker
        label={"Active On Date"}
        value={activeAt}
        onChange={setActiveAt}
        sx={{
          svg: { color: "black" },
          input: { color: "black" },
          label: { color: "black" },
          fontColor: "black",
          minWidth: "300px",
          borderRadius: "5px",
          backgroundColor: "#eee",
        }}
        required
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <Autocomplete
          value={book}
          options={scriptureBooks}
          renderInput={(params) => <TextField {...params} label="Book" />}
          onChange={(event, value) => {
            setBook(value);
          }}
          required
        />
        <TextField
          value={chapter}
          label="Chapter"
          onChange={setChapter}
          type="number"
          moreSx={{ minWidth: "70px" }}
          inputProps={{ min: 1 }}
          required
        />
        <TextField
          value={startVerse}
          label="Start Verse"
          onChange={setStartVerse}
          type="number"
          moreSx={{ minWidth: "70px" }}
          inputProps={{ min: 1 }}
        />
        <TextField
          value={endVerse}
          label="End Verse"
          type="number"
          moreSx={{ minWidth: "70px" }}
          onChange={setEndVerse}
          inputProps={{ min: 1 }}
        />
      </Box>
      <TextField
        placeholder={"Enter your prompt"}
        value={prompt}
        label="Prompt"
        onChange={setPrompt}
      />
    </Box>
  );
};

const OverwriteDialog = ({
  visible,
  handleClose,
  handleSubmit,
  existingScripture = {},
}) => (
  <Dialog
    open={visible}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Update Daily Scripture"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {`A daily scripture already exists for ${moment(
          existingScripture.activeAt
        ).format("MMMM Do, YYYY")}. Do you want to overwrite
        it?`}
      </DialogContentText>
      <DialogContentText id="alert-dialog-description">
        {`Existing Scripture: ${formatPassage(existingScripture)}`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Go Back</Button>
      <Button onClick={handleSubmit} autoFocus>
        Update Scripture
      </Button>
    </DialogActions>
  </Dialog>
);

// eslint-disable-next-line react/prop-types
export default function AddDailyScriptureModal({ handleClose, lastDate }) {
  const [values, setValues] = useState({});
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [existingScripture, setExistingScripture] = useState({});

  const handleSubmit = async (overwrite = false) => {
    const scripture = await restService.callEndpoint({
      endpoint: "getDailyScriptureByDate",
      body: { activeAt: values.activeAt },
      method: "post",
    });

    if (scripture && !overwrite) {
      setExistingScripture(scripture);
      setSaveModalOpen(true);
      return;
    }

    const result = await restService.callEndpoint({
      endpoint: "addDailyScripture",
      body: {
        ...values,
        ...(overwrite && existingScripture && { _id: existingScripture._id }),
      },
      method: "post",
    });
    setExistingScripture({});

    if (!result) {
      throw new Error("Failed to submit daily scripture entry");
    }
    return handleClose();
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{
              float: "right",
              fontSize: 15,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            X
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            {"Add Daily Scripture Reading"}
          </Typography>
          <RenderFields setValues={setValues} lastDate={lastDate} />
          <Button
            variant="contained"
            onClick={() => handleSubmit(false)}
            style={{
              position: "absolute",
              bottom: 40,
              right: 40,
              fontSize: 20,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Save
          </Button>
          <OverwriteDialog
            visible={saveModalOpen}
            handleClose={() => {
              setExistingScripture(false);
              setSaveModalOpen(false);
            }}
            handleSubmit={() => handleSubmit(true)}
            existingScripture={existingScripture}
          />
        </Box>
      </Modal>
    </div>
  );
}
