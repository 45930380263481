import { createContext, useCallback, useEffect, useState, useRef } from "react";

import { AdvancedImage, responsive, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Box, Button, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const DEFAULT_IMAGE_URL =
  "https://res.cloudinary.com/crossroads-community-church/image/upload/v1701876060/DEFAULT_COMMUNITY_PRAYER_BACKGROUND.png";

// Create a context to manage the script loading state
const CloudinaryScriptContext = createContext();

function CloudinaryUploadWidget({
  uwConfig,
  setImageDetails,
  buttonLabel = "Upload",
}) {
  // source: https://codesandbox.io/p/sandbox/upload-widget-in-react-with-sdk-pgx84k?file=%2Fsrc%2FCloudinaryUploadWidget.js
  const [loaded, setLoaded] = useState(false);

  const myWidget = useRef({ open: () => {} });

  const initializeCloudinaryWidget = useCallback(() => {
    myWidget.current = window.cloudinary.createUploadWidget(
      uwConfig,
      (error, result) => {
        if (!error && result && result.event === "success") {
          setImageDetails({
            publicId: result.info.public_id,
            url: result.info.secure_url,
            thumbnailUrl: result.info.thumbnail_url,
          });
        }
      }
    );
  }, [setImageDetails, uwConfig]);

  useEffect(() => {
    // Check if the script is already loaded
    if (!loaded) {
      const uwScript = document.getElementById("uw");
      if (!uwScript) {
        // If not loaded, create and load the script
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", "uw");
        script.src = "https://upload-widget.cloudinary.com/global/all.js";
        script.addEventListener("load", () => setLoaded(true));
        document.body.appendChild(script);
      } else {
        // If already loaded, update the state
        setLoaded(true);
      }
    } else if (loaded) {
      initializeCloudinaryWidget();
    }
  }, [loaded, initializeCloudinaryWidget]);

  return (
    <CloudinaryScriptContext.Provider value={{ loaded }}>
      <Button
        id="upload_widget"
        variant="contained"
        size="large"
        sx={{ width: "50%" }}
        onClick={() => myWidget.current.open()}
      >
        {buttonLabel}
      </Button>
    </CloudinaryScriptContext.Provider>
  );
}

CloudinaryUploadWidget.propTypes = {
  uwConfig: PropTypes.shape({
    cloudName: PropTypes.string.isRequired,
    uploadPreset: PropTypes.string.isRequired,
    sources: PropTypes.arrayOf(PropTypes.string),
    multiple: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.string),
    resourceType: PropTypes.string,
  }),
  setImageDetails: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
};

export default function Uploader({
  setImageUrl = () => {},
  renderEmpty,
  required = false,
}) {
  const [imageDetails, setImageDetails] = useState({});
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  const uwConfig = {
    cloudName,
    uploadPreset,
    sources: ["local", "url"], // restrict the upload sources to URL and local files
    multiple: false, //restrict upload to a single file
    tags: ["community_prayer"], //add the given tags to the uploaded files
    resourceType: "image", //specify the resource type to upload
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName,
    },
  });

  const myImage = cld.image(imageDetails.publicId);

  const updateSelectedImage = (args) => {
    setImageDetails(args);
    setImageUrl(args.url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Typography variant="h4">Background Image Details</Typography>
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">{`Image URL${
            required ? "*" : ""
          }`}</Typography>
          {imageDetails.url ? (
            <Link target="_blank" rel="noopener" href={imageDetails.url}>
              {imageDetails.url}
            </Link>
          ) : (
            renderEmpty()
          )}

          <CloudinaryUploadWidget
            uwConfig={uwConfig}
            setImageDetails={updateSelectedImage}
            buttonLabel={
              imageDetails.publicId ? "Change Image" : "Upload Image"
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            gap: "1rem",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            border: 1,
            borderRadius: "1rem",
            padding: "1rem",
          }}
        >
          <Typography variant="h5">Image Preview</Typography>
          {imageDetails.publicId ? (
            <AdvancedImage
              style={{ width: "75%" }}
              cldImg={myImage}
              plugins={[responsive(), placeholder()]}
            />
          ) : (
            <Box
              sx={{
                padding: "1rem",
                aspectRatio: "2/1",
                maxWidth: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>
                No image uploaded yet.
              </Typography>
              <Typography display="block">
                {'Click "Upload Image" to begin.'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
Uploader.propTypes = {
  setImageUrl: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func.isRequired,
  required: PropTypes.bool,
};
