import { useRef } from "react";

import { Box } from "@mui/material";

import CommitmentsTable from "../components/commitments/CommitmentsTable";

const Commitments = () => {
  const tableRef = useRef(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      <h1>Commitments</h1>
      <CommitmentsTable ref={tableRef} />
    </Box>
  );
};

export default Commitments;
