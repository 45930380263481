import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";

import Sidebar from "./Sidebar";

const AppContentWrapper = ({ children, setIsAuthenticated }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        overflow: "hidden",
        overscrollBehavior: "none",
      }}
    >
      <Sidebar setIsAuthenticated={setIsAuthenticated} />
      <Paper
        sx={{ flexGrow: 1, padding: "3rem", borderRadius: 0 }}
        elevation={12}
      >
        {children}
      </Paper>
    </Box>
  );
};
AppContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default AppContentWrapper;
